<template>
  <div>
    <div class="not-found-container">
      <OsiguLogo class="not-found-container__logo" />
    </div>
    <div class="not-found">
      <img
        class="not-found__image"
        src="../assets/images/notFound.png"
        alt="Image not found"
      />
      <div
        class="text-h3 secondary--text not-found__message"
      >
        {{ $t('error.pageNotFoundMessage') }}
      </div>
    </div>
  </div>
</template>

<script>
  import OsiguLogo from '@/assets/images/svg/OsiguLogo';
  
  export default {
    name: "PageNotFound",
    components: {
      OsiguLogo,
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
    },
  }
</script>

<style lang="scss">
  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
    padding: 20px;

    &__image {
      width: 175px;
      height: 175px;
      margin: 0 252px 28px 253px;
      object-fit: contain;
    }

    &__message {
      height: 93px;
      margin: 28px 0 25px;
      text-align: center;
    }
  }

  .not-found-container {
    background-color: #f9f9f9 !important;
    &__logo {
      margin-left: 10%;
      height: 100px;
      width: 100px;
    }
  }
</style>
